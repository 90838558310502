import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

export default class extends Controller {
  connect() {
    autosize(this.element)

    this.modalElement = this.element.closest('.modal')

    if (this.modalElement) {
      this.modalElement.addEventListener("modal:toggle", this._update.bind(this))
    }
  }

  disconnect() {
    if (this.modalElement) {
      this.modalElement.removeEventListener("modal:toggle", this._update.bind(this))
    }

    autosize.destroy(this.element)
  }

  _update() {
    autosize.update(this.element)
  }
}
